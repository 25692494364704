import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import axios from "@/lib/axios.client";
import { Social } from "@/lib/api/auth/type";
import { useRouter } from "next/navigation";
import { storeToken } from "@/lib/helper";

interface LoginPayload {
  id: number;
  email: string;
  referralCode?: string;
  walletAddress?: string;
  walletEncryptedKeystore?: string;
}

const SocialAuth = () => {
  const queryClient = useQueryClient();
  const router = useRouter();

  const loginUser = async (body: Social): Promise<LoginPayload> => {
    const response = await axios.post("/user-auth/handle-social-auth-v2", body);
    const payload = response.data as LoginPayload;

    if (!payload || typeof payload !== "object") {
      throw new Error("Invalid payload structure");
    }

    // Store other user data in localStorage
    storeToken("userId", payload.id);
    storeToken("email", payload.email);
    storeToken("referralId", payload.referralCode);
    storeToken("walletAddress", payload.walletAddress);
    storeToken("walletEncryptedKeystore", payload.walletEncryptedKeystore);

    return payload;
  };

  const { mutate, isPending, data, isSuccess, isError } = useMutation({
    mutationFn: loginUser,
    onSuccess: async (payload) => {
      await queryClient.invalidateQueries({ queryKey: ["socialLogin"] });
      toast.success("Logged in successfully! 🎉");
      router.push("/dashboard");
    },
    onError: (err: any) => {
      const errorMessage =
        err.response?.data?.message || err.message || "An error occurred";
      toast.error(errorMessage);
    },
  });

  return {
    mutate: (body: Social) => mutate(body),
    isPending,
    data,
    isSuccess,
    isError,
  };
};

export default SocialAuth;
