import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import axios from "@/lib/axios.client";
import { UserLogin } from "@/lib/api/auth/type";
import { useRouter } from "next/navigation";
import { setCookie } from "cookies-next";
import { storeToken } from "@/lib/helper";

const login = () => {
  const queryClient = useQueryClient();
  const router = useRouter();

  const loginUser = async (body: UserLogin) => {
    const payload = await axios.post("/user-auth/login", body);

    // Store token in an HTTP-only cookie
    setCookie("auth_token", payload.data.idToken);

    // Store other user data in localStorage
    storeToken("authToken", payload.data.idToken);
    storeToken("userId", payload.data.user.id);
    storeToken("email", payload.data.user.email);
    storeToken("referralId", payload.data.user.referralCode);
    storeToken("walletAddress", payload.data.user.walletAddress);
    storeToken(
      "walletEncryptedKeystore",
      payload.data.user.walletEncryptedKeystore
    );

    return payload;
  };

  const { mutate, isPending, data, isSuccess, isError } = useMutation({
    mutationFn: loginUser,
    onSuccess: async (payload) => {
      await queryClient.invalidateQueries({ queryKey: ["login"] });
      toast.success("Logged in successfully! 🎉");
      router.push("/dashboard");
    },
    onError: (err: any) => {
      toast.error(
        err.response?.data?.message ||
          err.message ||
          "An error occurred during login"
      );
    },
  });

  return {
    mutate: (body: UserLogin) => mutate(body),
    isPending,
    data,
    isSuccess,
    isError,
  };
};

export default login;
