// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBXJNvweZYoghJJo9C7C5vhHo9Ypp1f2ok",
  authDomain: "stabledoc-2-1.firebaseapp.com",
  databaseURL: "https://stabledoc-2-1-default-rtdb.firebaseio.com",
  projectId: "stabledoc-2-1",
  storageBucket: "stabledoc-2-1.appspot.com",
  messagingSenderId: "460000706033",
  appId: "1:460000706033:web:cada2b40b8874415fa9850",
  measurementId: "G-Q7W6Y4889Q",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
