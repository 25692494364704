"use client";
import React, { FC } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { Autoplay, Pagination } from "swiper/modules";
import Image from "next/image";

const Carousel: FC = () => {
  const swiperDetails = [
    {
      id: 1,
      image: "/assets/images/onboarding/onboarding-1.png",
      title: "The Best Doctors At Your Fingertips",
      alt: "group of doctors",
      body: "Find the best Doctors to get instant medical advice and second opinion for your health problems",
      width: 500,
      height: 250,
    },
    {
      id: 2,
      image: "/assets/images/onboarding/onboarding-1.png",
      title: "Online Consultation via Video/ Audio Call",
      alt: "group of doctors",
      body: "Consult with the world's top specialist doctors in any country from the comfort of your home",
      width: 500,
      height: 250,
    },
    {
      id: 3,
      image: "/assets/images/onboarding/onboarding-2.png",
      title: "Live Medical Reports from Experts",
      alt: "doctors with a report clipboard",
      body: "Consult with the world's top specialist doctors in any country from the comfort of your home",
      width: 350,
      height: 260,
    },
    {
      id: 4,
      image: "/assets/images/onboarding/onboarding-3.png",
      title: "Instant E-Prescriptions",
      alt: "doctor with a pill",
      body: "Get instant E-prescriptions from anywhere and get your prescriptions and medications delivered to you right at your food step",
      width: 330,
      height: 280,
    },
  ];

  return (
    <div className="w-full relative">
      <Swiper
        spaceBetween={50}
        centeredSlides={true}
        slidesPerView={1}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination]}
        className="mySwiper"
      >
        {swiperDetails.map((item) => (
          <SwiperSlide key={item.id}>
            <div className="flex flex-col text-center my-10 justify-center items-center text-white">
              <div className="mx-auto">
                <Image
                  src={item.image}
                  alt={item.alt}
                  width={item.width}
                  height={item.height}
                />
              </div>
              <p className="font-semibold text-xl mt-5">{item.title}</p>
              <p className="font-medium text-base mt-3 mb-10">{item.body}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Carousel;
