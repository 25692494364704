import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import axios from "@/lib/axios.client";
import { ChangePassword } from "@/lib/api/auth/type";

const changePassword = () => {
  const queryClient = useQueryClient();

  const changePassword = async (body: ChangePassword) => {
    const payload = await axios.post("/user-auth/change-password", body);
    return payload;
  };
  const { mutate, isPending, data, isSuccess, isError } = useMutation({
    mutationFn: changePassword,
    onSuccess: async (payload) => {
      await queryClient.invalidateQueries({ queryKey: ["change"] });
      toast.success("Password changed successfully! 🎉");
      window.location.reload();
    },
    onError: (err: any) => {
      toast.error(
        err.response.data.message ? err.response.data.message : err.message
      );
    },
  });
  return {
    mutate: (body: ChangePassword) => mutate(body),
    isPending,
    data,
    isSuccess,
    isError,
  };
};

export default changePassword;
