import dayjs from "dayjs";

export const errorParser = (errors: any, touched: any, type: string) => {
  if (touched[type] && errors[type]) {
    return errors[type];
  } else {
    return null;
  }
};

/**
 * Function that retrieves a given item from LocalStorage.
 * @param {String} key LocalStorage lookup key.
 * @returns Parsed object value associated with the given key or null.
 */
export const retrieveToken = (key: string) => {
  if (typeof window !== "undefined") {
    return JSON.parse(localStorage.getItem(key) ?? "{}");
  }
};

/**
 * Function that stores a given object by key in LocalStorage.
 * @param {String} key LocalStorage lookup key.
 * @param {Object} object The object to store in LocalStorage.
 */
export const storeToken = (key: string, object: any) => {
  localStorage.setItem(key, JSON.stringify(object));
};

export const readableDate = (timestamp: any) => {
  const date = new Date(timestamp);

  // Extract day, month, and year
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();

  // Format the date
  return `${day} ${month}, ${year}`;
};

export const readableTime = (timestamp: string) => {
  return dayjs(timestamp).format("hh:mm A");
};

export const numberWithCommas = (amount: any) => {
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
