import React, { FC } from "react";
import Image from "next/image";

interface IProps {
  loading: boolean;
}

const Loader: FC<IProps> = ({ loading = false }: IProps) => {
  return (
    <>
      {loading ? (
        <div className="loader w-full h-screen flex items-center justify-center fixed inset-0 bg-black bg-opacity-50">
          <div className="relative">
            <Image
              className="loader-logo"
              src="/assets/small-logo.svg"
              alt="logo"
              width={80}
              height={80}
            />
            <div className="circular-progress"></div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Loader;
